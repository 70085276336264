.calculator-result {
  @apply -mb-24;

  .calculator-result-container {
    @extend .ms-page;

    .calculator-result__calculation {
      @extend .cdf;
      @apply py-[40px] md:py-11 mt-[30px] md:mt-11 mb-0 px-[30px] md:px-14;

      .calculator-result__calculation__inner {
        @apply text-center;

        .subtitle_2 {
          @apply text-[16px] md:text-[19px];
        }

        .calculator-result__calculation__inner__value {
          @apply md:py-4 flex justify-center;

          p {
            @apply bg-white md:w-full p-2 max-w-[200px] mr-2 md:mr-6 ml-0 my-0 font-['Helvetica'] text-[22px] md:text-[28px] border-[1px] border-solid border-[#dddddd] rounded-[5px];
          }

          a {
            @apply font-normal text-[11px] md:text-xs px-4 no-underline flex items-center leading-[9px];
          }
        }

        h5 {
          @apply mt-0 mb-5;
        }

        p {
          @apply max-w-screen-sm mx-auto;
        }
      }

      .cdf {
        @apply mt-9 md:mt-[70px] p-0 m-0 border-0 bg-transparent;

        h5 {
          @apply hidden;
        }
      }
    }
    .calculator-result__calculation-footer {
      @apply text-center mt-[18px] mb-0 md:mt-9 text-[13px] md:text-[15px] text-[#5a5a5a] font-['Helvetica'];

      a {
        @apply text-[13px] md:text-[15px] text-[#5a5a5a] underline;
      }
    }
  }

  .calculator-result__reviews,
  .calculator-result__awards {
    @apply bg-gray-lighter;
  }

  .calculator-result__awards__intro {
    @apply container text-center py-8 mt-16;

    p {
      @apply max-w-[980px] mx-auto;
    }

    .calculator-result__awards__img {
      background-image: url('/images/law-society-and-modern-law-awards-mobile.svg');

      @apply w-full h-[18rem] bg-contain bg-no-repeat bg-center mt-12;

      @screen md {
        background-image: url('/images/law-society-and-modern-law-awards-desktop.svg');
        @apply h-[10.5rem];
      }
    }
  }

  .calculator-result__reviews {
    @apply mb-[6rem] md:mb-0;

    h3 {
      a {
        @apply font-sans text-[19px] md:text-[36px] font-[700];
      }
    }
  }
}
