.header-mobile {
  @apply shadow-md sticky top-0 z-50 bg-white;

  a {
    @apply text-white no-underline;
  }

  @screen lg {
    @apply hidden;
  }

  .header-mobile__logo {
    @apply w-28 h-12 bg-center bg-contain bg-no-repeat;
  }

  .header-mobile__wrapper {
    @apply flex flex-row justify-between py-[4.5px];

    .header-mobile-burger{
      @apply flex items-center ml-4 justify-center;

      .header-mobile__right__search {
        img {
          box-shadow: 1px 6px 8px 0 rgba(0, 0, 0, 0.06);
          @apply rounded-[50%] ml-5;
        }
      }
    }

    .header-mobile__right {
      @apply mr-4 flex items-center justify-end;

      .contact-button {
        @apply text-black border-[1px] border-solid border-[#50acfb] px-[7px] py-[10.5px] text-[11px] leading-[0.82] font-semibold rounded-[5px];
      }
    }

    .header-mobile_right__phone {
      @apply p-2 w-4 h-4 bg-contain bg-no-repeat;
    }
  }

  .header-mobile__navigation {
    @apply absolute bg-gray w-full font-sans p-4 z-30 text-white pb-24 overflow-x-hidden overflow-y-auto h-[100vh];
    animation: fadeIn 0.5s;

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    svg {
      .cls-1 {
        @apply fill-[#fff] stroke-[#fff];
      }
    }

    .header-mobile__navigation__top {
      @apply pl-[18px] pr-[35px] mt-12;

      li, a {
        @apply flex justify-between text-[19px] font-semibold leading-[16px] tracking-[-0.48px];
      }
    }

    .header-mobile__navigation__bottom {
      @apply pl-[18px] pr-[35px];

      p {
        @apply text-[17px] leading-[16px] tracking-[-0.43px] mt-0 font-semibold;
      }

      li, a {
        @apply flex justify-between text-[19px] font-semibold leading-[16px] tracking-[-0.48px];
      }

      .header-mobile__navigation__us {

        li {
          &:last-child {
            @apply hidden;
          }
        }

        a {
          @apply text-[15px] mt-0 mb-[23px] leading-[12px] tracking-[-0.3px] block;
        }
      }
    }

    .header-mobile__navigation__bottom {

      .header-mobile__navigation__about-us {
        @apply flex justify-between;
      }

      .header-mobile__navigation__us {
        @apply flex flex-col;
      }

      ul {
        @apply mt-0;
      }
    }

    .header-mobile__navigaton__controls {
      @apply flex justify-end justify-between items-center;

      .header-mobile__navigaton__submenu-title {
        @apply text-[16px] font-[600] leading-[16px] tracking-[-0.48px];
      }

      .header-mobile__navigation__close {
        @apply text-right pr-[10px];

        svg {
          .cls-1 {
            @apply stroke-[2px];
          }
        }
      }
    }

    .header-mobile__navigation__controls-submenu {

      svg {
        .cls-1 {
          @apply stroke-[2px];
        }
      }

      .header-mobile__navigaton__title {
        @apply text-center text-[16px] font-[600] leading-[16px] tracking-[-0.48px];
      }

      .header-mobile__navigation__close__submenu {
        @apply absolute top-[17px] right-[24px];
      }

    }

    .header-mobile__navigaton__sub-submenu-title {
      @apply max-w-[192px] mx-auto relative bottom-[20px] bg-gray text-center text-[16px] font-[600] leading-[16px] tracking-[-0.48px];
    }

    ul {
      @apply list-none pl-0 mb-0;

      div {
        @apply w-full;
      }

      li {
        @apply mb-[30px];
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.header-mobile--menu-open {

  .header-mobile__navigation {

    .guides-menu,
    .header-mobile__navigation__controls-submenu,
    .header-mobile__navigaton__sub-submenu-title,
    .header-mobile__navigation__close,
    .topic-menu {
      -webkit-animation: moveFromRight 0.3s ease both;
      animation: moveFromRight 0.3s ease both;

      @-webkit-keyframes moveFromRight {
        from { -webkit-transform: translateX(100%); }
      }

      @keyframes moveFromRight {
        from { -webkit-transform: translateX(100%); transform: translateX(100%); }
      }
    }

    .header-mobile__navigaton__controls {
      @apply flex justify-between;
      -webkit-animation: moveFromRight 0.3s ease both;
      animation: moveFromRight 0.3s ease both;

      @-webkit-keyframes moveFromRight {
        from { -webkit-transform: translateX(100%); }
      }

      @keyframes moveFromRight {
        from { -webkit-transform: translateX(100%); transform: translateX(100%); }
      }
    }

    .header-mobile__navigaton__controls__back_submenu {
      @apply absolute top-[16px] left-[16px];

      -webkit-animation: moveFromRight 0.03s ease both;
      animation: moveFromRight 0.03s ease both;

      @-webkit-keyframes moveFromRight {
        from { -webkit-transform: translateX(100%); }
      }

      @keyframes moveFromRight {
        from { -webkit-transform: translateX(100%); transform: translateX(100%); }
      }
    }
  }
}

