.section__autoschedule {
  .section__autoschedule__options {
    @apply mb-[40px];

    &:first-child {
      @apply mb-14 md:mb-[6rem];
    }

    .section__autoschedule__option {
      @apply md:flex gap-[60px] items-center mb-5 md:mb-[60px] text-center md:text-left;

      h3 {
        @apply md:mt-0 md:mb-2;
      }

      ul {
        @apply pl-[30px] mb-10 md:m-0 text-left;
      }

      p {
        @apply mb-8 md:mb-10 mt-0;
      }

      img {
        @apply max-w-[440px];
      }
    }

    .section__autoschedule__options__products {
      @apply grid grid-cols-3 text-center gap-1 mb-1 max-w-[1200px] mx-auto;

      p {
        font-weight: bold !important;
        line-height: 1 !important;
        display: flex !important;
        @apply bg-[#f8f9fa] p-[10px] md:p-5 m-0 flex md:flex flex-col justify-center;

        span {
          font-weight: 400 !important;
          line-height: 1 !important;
        }

        .button {
          @apply text-sm p-[10px] md:px-5 md:py-[10px] capitalize no-underline bg-[#007bff];
        }
      }
    }
    .section__autoschedule__buttons {
      @apply flex flex-col gap-3;
      .section__autoschedule__buttons__single {
        @apply flex lg:flex-row flex-col sm:gap-2 gap-1 items-center;
        a.card {
          @apply block sm:min-w-[118px] min-w-[88px] h-[40px];
        }
        a.paypal {
          @apply bg-[#FEC439] px-[10px] block h-[40px] sm:min-w-[118px] min-w-[88px];
        }
        strong {
          @apply sm:text-[12px] text-[10px] lg:text-[15px] font-semibold text-left sm:leading-6 leading-3;
        }
      }
    }
  }
}

.calendly {
  @apply max-w-[1200px] m-auto mb-5 md:mb-0;

  .calendly-inline-widget {
    height: 780px !important;
  }
}
