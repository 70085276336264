.templates {
  .templates__introduction {
    @apply container text-center;

    h1,
    p {
      @apply md:w-2/3 mx-auto my-16;
    }

    .section__video, .section__video__wrapper {
      @apply md:m-0;
    }
  }

  .ms-text {
    @apply max-w-[768px] mx-auto px-4 md:px-0;

    h5 {
      @apply mb-4 mt-8 md:mt-[60px];
    }

    p {
      @apply mb-4 md:mb-[30px];
    }
  }

  .templates__list {
    @apply mt-12 md:mt-36 bg-gray-lighter mx-auto;

    @screen md {
      @apply w-1/2;
    }

    .templates__list__template {
      box-shadow: 0 3px 6px 0 rgb(0 0 0 / 7%);

      @apply bg-white p-8 my-12;

      .templates__template__content {
        @apply flex flex-col items-center;

        h5 {
          @apply underline m-0;
        }

        .button {
          @apply text-center text-white;
        }

        a {
          @apply text-[#0096F4] mx-auto no-underline mt-8;
        }
      }
    }
    .templates__list__topic {
      @apply px-[2.5rem] py-[3.4375rem] border-white border-solid border-b-[3px] border-0;

      p {
        @apply mb-0;
      }

      .templates__topic__title {
        @apply flex justify-between items-center;

        h4 {
          @apply m-0;
        }
      }
    }
  }

  ul {
    @apply p-0;
  }

  li {
    @apply list-none;
  }

  .reviews__container {
    h3 {
      @apply mb-1;

      a {
        @apply font-sans text-[19px] md:text-[36px] font-[700];
      }
    }

    p.subtitle_1 {
      @apply max-w-[300px] md:max-w-[unset] pb-[10px] md:pb-0;
    }

    .custom__reviews__section {
      @apply mb-[24px] md:mb-[54px] md:pt-[30px];
    }

    .reviews__container__footer-text {
      @apply mt-0;
    }
  }
}

.templates__list__topic--open {
  svg {
    transform: rotate(180deg);
  }
}
