.cdf2__page,
.cdf3__page {
  .cdf2,
  .cdf3 {
    @apply text-left;

    h5 {
      @apply md:m-0 text-center md:text-left mt-0 mb-4;
    }

    .cdf__form {
      .cdf__field__wrapper {
        @apply mb-8;

        &.create__account {
          @apply hidden;
        }

        &.checkbox {
          @apply bg-transparent p-0 mb-0 mt-4;

          label {
            @apply items-start mb-1;
          }

          .checkbox__details {
            @apply ml-8;

            span {
              @apply mb-1 block;

              &.less,
              &.more {
                @apply text-[#626262] block text-sm;
              }
            }

            .show__hide {
              @apply text-[#767676] flex items-end text-[14px] leading-5 cursor-pointer;

              img {
                @apply w-4;
              }
            }
          }
        }

        p {
          @apply m-0;
        }

        > span {
          @apply text-[#000] block;
        }

        textarea {
          @apply mt-[10px];
        }
      }

      p {
        @apply mt-5 md:mt-[28px] mb-[10px] font-semibold text-[#000];
      }

      .cdf__form__multi-select-field {
        @apply md:w-[550px] w-full md:mr-6;

        .react-select__control {
          box-shadow: unset;
          @apply min-h-[2.5rem] rounded-[5px] w-full text-[15px] md:text-[17px] font-sans text-[#727272] pl-[12px] border-[1px] border-[#dddddd] border-solid;

          .react-select__value-container {
            @apply p-0;

            .react-select__input-container {
              @apply p-0 m-0;
            }

            .react-select__multi-value {
              @apply bg-[#f7f7f7];

              .react-select__multi-value__label {
                @apply text-base text-[#727272];
              }
            }
          }

          .react-select__indicators {
            .react-select__indicator-separator {
              @apply hidden;
            }
            .react-select__indicator {
              @apply w-4 ml-2 p-0 text-[#727272];
            }
          }
        }

        .react-select__menu {
          @apply mt-0 p-0;

          div {
            @apply pt-0 pb-[2px] text-[#727272] text-base;
          }
        }

        input {
          @apply h-10 rounded-[5px] border-[1px] border-[#dddddd] border-solid;
        }

        .cdf__form__whistleblowing__text {
          @apply font-medium mt-2 mb-0 text-[#00a7ff] md:whitespace-nowrap;
        }

        .cdf__form__multi-select-field__discrimination {
          @apply md:flex;

          > div {
            @apply mr-[30px] md:flex md:flex-col justify-end md:w-1/2 w-full;

            &:last-child {
              @apply mr-0;
            }
          }

          input {
            @apply w-full;
          }
        }
      }

      .cdf__form__select-field {
        @apply md:flex mb-4 mb-5;

        .cdf__form__select-field__wrapper {
          @apply w-full mb-4 md:mb-0 mt-4 md:mt-0 mr-[30px];

          &:last-child {
            @apply mr-0;
          }

          select {
            outline: unset;
            @apply h-10 rounded-[5px] w-full text-[15px] md:text-[17px] font-sans text-[#727272] pl-[12px] border-[1px] border-[#dddddd] border-solid;
          }
        }
      }
    }
  }
}
