.about-menu {
  @apply bg-gray z-30 absolute w-full h-[100vh] font-sans pt-6 pl-[18px] md:pl-0;

  -webkit-animation: moveFromRight 0.3s ease both;
  animation: moveFromRight 0.3s ease both;

  @-webkit-keyframes moveFromRight {
    from { -webkit-transform: translateX(100%); }
  }

  @keyframes moveFromRight {
    from { -webkit-transform: translateX(100%); transform: translateX(100%); }
  }

  @screen md {
    @apply mt-[92px] h-auto py-12 animate-none;
  }

  .about-menu__container {
    @apply flex flex-col w-full justify-center mx-auto;

    @screen md {
      @apply flex-wrap flex-row w-3/4;
    }

    a {
      @apply font-sans text-[19px] text-white no-underline;
    }

    .about-menu__topic-container {
      @apply flex flex-col mt-8 md:mt-0;

      @screen md {
        @apply grid grid-cols-2 gap-x-48 mb-12;
      }

      a {
        @apply my-1;
      }

      @media (max-width: 767px) {

        a {
          @apply text-[19px] mt-0 mb-[35px] leading-[12px] tracking-[-0.3px] block font-[500];
        }
      }
    }
  }
}
