.section__call-to-action-fullwidth {
  @apply relative my-16;

  @screen md {

    &:before {
      content: '';
      transform: translateX( -50%);
      width: calc(1810px - 33.3%);
      background: linear-gradient(to right, rgba(245, 247, 250, 0) 9%, #f5f7fa 31%, #f5f7fa 99%);

      @apply absolute top-0 left-0 h-full;
    }
  }


  .section__call-to-action-fullwidth__inner {
    @apply relative flex flex-col-reverse md:flex-row justify-between items-center text-center md:text-left max-w-[275px] m-auto md:max-w-full md:m-0;

    .section__call-to-action-fullwidth__inner__main-text {
      letter-spacing: -1.2px;

      @screen md {
        letter-spacing: -1.45px;
      }

      @apply text-[24px] md:text-[29px] text-[#2a4462] m-0;
    }

    .section__call-to-action-fullwidth__inner__sub-text {
      letter-spacing: -0.19px;

      @screen md {
        letter-spacing: -0.22px;
      }

      @apply text-[19px] md:text-[22px] text-[#2c2e2f] mt-3 mb-6 md:mb-7;
    }

    .section__call-to-action-fullwidth__inner__btn {
      letter-spacing: 0;
      box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, 0.07);

      @apply block text-base md:text-[19px] capitalize text-center rounded-[8px] px-6 py-3 w-max m-auto md:m-0 md:px-10 md:py-4 leading-3 no-underline;

      &.primary {
        @apply text-[16px] md:text-[19px] bg-[#0096F4] text-white rounded-md border-0 outline-none font-sans font-semibold tracking-wide;

        &:hover {
          @apply no-underline;
        }
      }

      &.outline {
        @apply text-[16px] md:text-[19px] font-semibold text-[#454545] border-2 border-solid border-main bg-transparent;

        &:hover {
          @apply no-underline;
        }
      }
    }

    img {
      transform: scale(1.05);
      box-shadow:0 50px 30px 0 rgba(0, 0, 0, 0.02);

      @apply mb-6 md:ml-8 md:mb-0 max-w-[170px] max-h-[170px] md:max-w-full md:max-h-full rounded-full;
    }
  }
}
