.toc {
  @screen md {
    @apply hidden;
  }
}

.sidebar {
  @apply flex flex-col mt-8 mb-6;

  .toc {
    @apply mb-8 hidden;

    @screen md {
      @apply block;
    }
  }

  .sidebar__grapple {
    @apply relative rounded-lg;

    .sidebar__grapple__image {
      @apply rounded-lg;
    }

    .sidebar__grapple__info {
      @apply left-0 right-0 mx-auto  text-[26px] md:text-[20px]  lg:text-[26px] leading-8 md:leading-6 lg:leading-8  top-[100px]  md:top-[45px] lg:top-[100px] font-bold text-center text-[#000204] absolute m-0 pl-3 pr-3 2xl:mx-14;
    }

    .sidebar__grapple__info_desc {
      @apply left-0 right-0 mx-auto tracking-wide  text-[20px] md:text-[14px]  lg:text-[20px] top-[180px] md:top-[100px] lg:top-[180px] text-center text-[#000204] absolute m-0 pl-3 pr-3 leading-6 2xl:mx-14;
    }

    .sidebar__grapple__button {
      @apply capitalize px-[20px] font-semibold  py-[15px] md:py-2 lg:py-[15px] w-[260px] md:max-w-[80%] lg:w-[260px] text-center bg-[#3576D9] m-auto left-0 right-0 absolute block top-[300px] md:top-[180px] lg:top-[270px] rounded-[60px] text-base no-underline;
    }
  }

  .sidebar__grapple-new {
    @apply bg-[#346ccc] py-4 text-center rounded-lg p-3;
    &__button {
      @apply mt-12 capitalize text-base text-[#313030] font-semibold tracking-[0.5px] bg-white p-3 rounded-full max-w-[280px] w-full block text-center mx-auto hover:opacity-70 hover:no-underline;
      font-weight: 600 !important;
    }
    &__info {
      @apply text-center mx-auto font-sans text-2xl font-bold text-white mt-10  mb-4 tracking-[0.5px];
    }
    &__text {
      @apply max-w-[456px] sm:max-w-[670px] xl:max-w-[850px] text-center mx-auto text-white my-0 text-base  tracking-[0.5px] font-normal font-sans  ;
    }
  }


  .toc,
  .sidebar__helpful-guides {
    ul {
      @apply list-none pl-0 mb-0;
    }
  }

  &.sidebar--guides-collapsed {
    .sidebar__helpful-guides__title {
      border-bottom: 1px solid #dddddd;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .sidebar__helpful-guides {
    border: 1px solid;
    @apply border-[#DDDDDD] rounded-[0.5rem] mb-[25px];

    .section__helpful-guides {
      @apply p-4 mb-0 block;

      h3 {
        @apply hidden;
      }

      ul {
        @apply mt-0;

        li {
          @apply pb-0;

          a {
            @apply mb-[15px] flex justify-start no-underline font-sans text-[16px] text-[#0096F4] leading-[22px];
          }

          &:last-child {
            a {
              @apply mb-[12px];
            }
          }
        }
      }
    }

    .sidebar__helpful-guides__title {
      @apply p-4 bg-gray-lighter rounded-[0.5rem];

      strong {
        @apply block text-lg font-sans font-semibold flex justify-between items-center text-[#5a5a5a] font-[700] text-[19px] leading-[30.59px];
      }
    }
  }

  .sidebar__calculator {
    @apply bg-gray-lighter border-[#DDDDDD] border-solid rounded p-2 mt-12;

    .calculator,
    .calculator__result__wrapper {
      h3 {
        @apply block text-lg font-sans font-semibold flex justify-between items-center text-black m-0;
      }
    }

    .calculator {
      @apply p-2;

      .calculator__first-step {
        @apply mb-4;

        .calculator__first-step__field {
          @apply w-full mb-4 px-0;

          @screen lg {
            @apply w-1/2;

            &:nth-child(even) {
              @apply pl-2;
            }

            &:nth-child(odd) {
              @apply pr-2;
            }
          }

          p {
            @apply text-sm mb-1;
          }
        }
      }

      .calculator__second-step {
        @apply w-full;

        p {
          @apply text-sm mb-1;
        }

        .calculator__second-step__field {
          @apply w-full mb-4 px-0;

          @screen xl {
            @apply w-1/2;

            &:nth-child(even) {
              @apply pl-2;
            }

            &:nth-child(odd) {
              @apply pr-2;
            }
          }
        }
      }
    }
  }

  .calculator__result__wrapper {
    @apply p-4;
  }
}

.menu__cdf {
  @apply mt-12;
}

.sidebar__cdf {
  @apply mt-[20px] md:mt-[25px];
}

.sidebar__cdf,
.menu__cdf {
  .cdf {
    @apply m-0 px-6 pt-6 pb-9 rounded-[0.5rem];

    .cdf__form {
      .cdf__form__first-step,
      .cdf__form__second-step {
        h5 {
          @apply text-[19px] text-center tracking-[-0.54px] mb-[11px];
        }

        p {
          @apply mt-0 mb-8 text-base text-center font-normal tracking-[-0.45px];
        }

        .cdf__first-step__fields,
        .cdf__second-step__select {
          @apply block;

          .cdf__field__wrapper,
          .cdf__select-field__wrapper {
            @apply w-full mb-4;

            .cdf__select-field__wrapper__inner {
              @apply w-full;
            }

            p {
              @apply mb-[10px] text-left leading-[22px] tracking-[-0.13px] h-auto;
            }

            input,
            select {
              @apply w-full;
            }
          }
        }

        .cdf__form__third-step {
          @apply mb-3 md:pr-0;

          p {
            @apply mb-[10px] text-left text-[14px] leading-[22px] tracking-[-0.13px] h-auto font-bold;
          }
        }

        .cdf__second-step__fields {
          @apply mt-6;
        }
      }

      .cdf__form__second-step {
        .cdf__field__wrapper {
          @apply mb-4;

          p {
            @apply mb-[10px] text-left leading-[22px] tracking-[-0.13px] h-auto;
          }

          input,
          select {
            @apply w-full;
          }
        }
      }

      .cdf__multi-select-field {
        @apply flex flex-wrap;

        p {
          @apply mb-[10px] text-left text-[14px] leading-[22px] tracking-[-0.13px] h-auto font-bold;
        }

        .cdf__multi-select-field__wrapper.discrimination {
          > div {
            &:first-child {
              @apply mt-0;
            }
          }
        }
      }

      .cdf__field__footer-text {
        @apply text-[13px] mt-2 mb-0;
      }

      .cdf__field__error {
        @apply text-[12px];
      }

      .cdf__field__required {
        @apply font-normal mt-0 mb-2 text-[13px] text-[#7b7b7b];
      }

      button {
        @apply text-center mx-auto font-semibold text-[11px] leading-[15px] tracking-[-0.11px] w-[105px] rounded-[5px];
      }
    }
  }
}
