.popup-overlay {
  @apply fixed inset-0 z-50 flex flex-col justify-end pointer-events-none;

  .popup {
    @apply pointer-events-auto overflow-hidden shadow-[0px_0px_19.399999618530273px_0px_#00000030] pt-9 pb-4 max-w-[1000px] relative mx-auto z-50 w-full bg-[#CEE6F9];

    &__image {
      @apply max-h-[160px] md:max-h-[unset] absolute -z-10 bottom-0 -right-24 md:right-0 xl:right-0 h-full object-contain pointer-events-none;
    }
    &__close-button {
      @apply absolute top-2.5 right-3 cursor-pointer invert;

      img {
        filter: invert(1);
        -webkit-filter: invert(1);
      }
    }

    &__info {
      @apply max-w-[256px] sm:max-w-[470px] xl:max-w-[550px] text-center mx-auto font-sans text-2xl font-bold text-black mb-2 mt-0;
    }
    &__text {
      @apply max-w-[256px] sm:max-w-[470px] xl:max-w-[550px] text-center mx-auto text-black my-0 text-sm leading-[1.6] font-normal font-sans tracking-normal;
    }

    &__button {
      @apply mt-9 capitalize text-base text-white bg-[#3576D9] p-3 rounded-full max-w-[315px] w-full block text-center mx-auto hover:opacity-70 hover:no-underline;
    }
  }
}

.popup-overlay-new {
  @apply fixed inset-0 z-50 flex flex-col justify-end pointer-events-none  ;

  .popup {
    @apply pointer-events-auto overflow-hidden shadow-[0px_0px_19.399999618530273px_0px_#00000030] pt-4 pb-4 max-w-[1000px] relative mx-auto z-50 w-full rounded-t-lg bg-[#282828] text-center;
    &__close-button {
      @apply absolute top-2.5 right-3 cursor-pointer invert;

      img {
        filter: invert(0);
        -webkit-filter: invert(0);
        }
    }
    &__button {
      @apply mt-9 capitalize text-base text-white font-normal tracking-[0.5px] bg-[#3576D9] p-3 rounded-full max-w-[315px] w-full block text-center mx-auto hover:opacity-70 hover:no-underline;
    }
    &__info {
      @apply    text-center mx-auto font-sans text-2xl font-bold text-white mb-2 my-4 tracking-[0.5px] ;
    }
    &__text {
      @apply max-w-[356px] sm:max-w-[570px] xl:max-w-[750px] text-center mx-auto text-white my-0 text-base  tracking-[0.5px] font-normal font-sans  ;
    }
  }
}
