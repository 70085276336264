.page-layout {
  &.ms-page {
    h1,
    h2 {
      font-family: 'Poppins', sans-serif;
      @apply font-semibold;
    }
    h1 {
      @apply text-[28px] md:text-[45px] leading-[40.6px] md:leading-[65.25px] tracking-[-2%];
    }
    h2 {
      @apply text-[26px] leading-[33.8px] tracking-[-1%];
    }
    h3 {
      font-family: 'Manrope', sans-serif;
      @apply font-bold text-[22px] leading-[33px];
    }
    p,
    a,
    span,
    li,
    ul {
      font-family: 'Manrope', sans-serif;
      @apply text-[16px] font-normal leading-[24px];
    }
    .section__call-to-action-fullwidth {
      .section__call-to-action-fullwidth__inner {
        .section__call-to-action-fullwidth__inner__main-text {
          font-family: 'Poppins', sans-serif;
          @apply text-[26px] leading-[33.8px] tracking-[-1%] font-semibold;
        }
        .section__call-to-action-fullwidth__inner__sub-text {
          font-family: 'Manrope', sans-serif;
          @apply text-[16px] font-normal leading-[24px];
        }
        .section__call-to-action-fullwidth__inner__btn {
          font-family: 'Manrope', sans-serif;
          @apply text-[16px] font-bold leading-[24px];
        }
      }
    }
  }
}
