.dihac__cdf {

  &.dihac__cdf3 {

    .dihac__cdf {
      margin-left: auto !important;
      margin-right: auto !important;
      @apply max-w-lg;

      .cdf__first-step__fields {
        @apply block;

        .cdf__field__wrapper {
          @apply mb-6;

          &:last-child {
            @apply mb-0
          }
        }
      }
    }
  }

  .dihac__cdf {

    .cdf__first-step__fields,
    .cdf__second-step__fields {
      @apply flex flex-col lg:flex-row gap-8 md:gap-6 xl:gap-8 mb-7;

      .cdf__field__wrapper {
        @apply m-0 w-full;

        input {
          @apply h-10 rounded-[5px] border-[1px] border-[#dddddd] border-solid w-full;
        }

        select {
          @apply h-10 rounded-[5px] w-full text-[15px] md:text-[17px] font-sans text-[#727272] pl-[12px] border-[1px] border-[#dddddd] border-solid;
        }

        p {
          @apply mb-[10px];
        }
      }
    }

    .cdf__third-step__fields {
      @apply block md:flex gap-8;

      .cdf__form__multi-select-field__wrapper {
        @apply mb-7 w-full;

        p {
          margin-top: 0;
        }
      }
    }

    .cdf__form__final-step {
      @apply bg-transparent p-0 m-0 mt-7;

      label {
        @apply mb-1;
      }

      .cdf__field__wrapper {
        @apply mb-4;

        .checkbox__details {
          @apply ml-8;

          span {
            @apply block mb-1;

            &.less, &.more {
              @apply text-[#626262] block text-sm;
            }
          }

          .show__hide {
            @apply text-[#767676] flex items-end text-[14px] leading-5 cursor-pointer;

            img {
              @apply w-4;
            }
          }
        }

        &.phone {
          p {
            @apply mb-[10px] mt-8;
          }
          input {
            @apply h-10 rounded-[5px] border-[1px] border-[#dddddd] border-solid w-full;
          }
        }
      }
    }

    .cdf__field__error {
      @apply block;
    }
  }
}
