.representation-process__container {
  @apply flex flex-col items-center justify-between py-16 bg-gray-lighter container;

  h2 {
    @apply mt-0 md:mt-10 mb-[19px];
  }

  .subtitle_1 {
    @apply mt-0 text-center;
  }

  .representation-process__footer-text {
    @apply text-center max-w-[500px] mx-auto mb-0;
  }

  .representation-process__flow {
    @apply flex flex-col items-center my-10 md:my-20;

    @screen md {
      @apply flex-row w-4/5;
    }

    .representation-process__single-arrow {
      transform: rotate(90deg);
      @apply w-8 h-8 bg-no-repeat bg-contain bg-center mb-4;

      @screen md {
        transform: rotate(0deg);

        @apply mb-32;
      }
    }

    .representation-process__double-arrow {
      background-image: url('/images/double-arrow.png');
      transform: rotate(90deg);

      @apply w-20 h-32 bg-no-repeat bg-contain bg-center;

      @screen md {
        transform: rotate(0deg);

        @apply mb-32;
      }
    }

    .representation-process__card {
      @apply flex-col flex w-52 mx-2 text-center items-center relative;

      .representation-process__card__image {
        @apply w-full bg-contain bg-center bg-no-repeat h-36;

        &.representation-process__card__image--contact {
          @apply md:mt-[-30px];
        }

        @screen md {
          @apply mt-0;
        }
      }
    }

    .representation-fee {
      @apply flex;

      @screen md {
        @apply flex-col w-1/3 mb-32;
      }

      .representation-fee__card {
        @apply flex flex-col items-center w-24 mx-4 text-center;

        @screen md {
          @apply flex-row ml-4 my-5 mr-0  w-full text-left my-2;

          p {
            @apply w-[70%] my-0 ml-8;
          }
        }
      }
    }
  }
}
