.search-page {

  .search__result {
    @extend .ms-page;
    @apply mt-[29px] md:mt-14;

    .search__result__count {

      p {
        @apply text-xs md:text-[15px] font-medium tracking-[-0.36px] md:tracking-[-0.45px] text-[#939393] mb-[20px] md:mb-[26px];
      }
    }
    .search__result__item {
      @apply mb-10 md:mb-16;

      h5 {
        @apply mb-4 md:mb-[20px] underline decoration-main;

        a {
          @apply text-inherit no-underline;
          @extend h5;
        }
      }
    }
  }

  .search-page__loader {
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;

    @apply w-[75px] h-[75px] my-40 mx-auto bg-transparent border-solid border-transparent border-t-4 border-t-main border-r-4 border-r-transparent rounded-[50%];
  }

  @-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
}
