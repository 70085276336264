.homepage {
  @apply text-center;

  h2 {
    @apply mb-[19px];
  }

  .section__blog__list {
    @apply pb-12 md:pb-24;
  }

  .section__blog .section__blog__item__content-wrap {
    @apply text-left;

    .section__blog__item__title {
      @apply text-[19px];

    }
    .blog__date {
      @apply text-[13px];
    }
    .blog__desc * {
      @apply text-[15px] md:text-[15px];
    }
  }

  .homepage__articles {
    @apply bg-[#F6F6F6] pt-14 pb-14 md:pb-20;

    &.blog {
      @apply bg-transparent pb-0;
    }

    .homepage__articles__header {
      @apply flex justify-between container items-center mb-7 md:mb-9;

      h3 {
        @apply text-[21px] md:text-[28px] font-semibold text-[#353535] m-0;
      }

      a {
        @apply text-base font-semibold flex no-underline;

        img {
          @apply w-[18px] ml-[8px] h-5 mt-[2px] no-underline;
        }
      }
    }

    .homapage__articles__list {
      @apply md:flex gap-[26px] container text-left;

      a {
        @apply w-full block no-underline mb-12 md:mb-0;

        img {
          @apply w-full;
        }
        p {
          @apply text-[19px] font-semibold text-[#353535] mt-2 mb-0;
        }
      }
    }
  }

  .homepage__lawyers-banner {
    @apply pt-9 md:pt-20;

    .homepage__lawyers-banner__container {
      background-size: 100%;

      @apply flex h-[360px] flex-col justify-start items-center bg-bottom bg-no-repeat;

      @screen md {
        @apply h-[520px];
      }

      .homepage__lawyers-banner__container__title {
        @extend h1;
        @apply mb-0 mt-0 md:mt-[50px] max-w-[300px] md:max-w-[600px];
      }

      .homepage__lawyers-banner__cta {
        @apply mt-5 md:mt-36 flex flex-col;

        @screen md {
          @apply mt-28;
        }

        a {
          @apply text-sm md:text-lg no-underline font-semibold;
        }

        small {
          @apply text-gray-light mt-4;
        }

        p {
          @apply text-[13px] md:text-[15px] m-0 flex text-center items-center justify-center;

          img {
            @apply md:mt-[2px] ml-1;
          }
        }
      }

      &.mobile {
        @apply flex md:hidden;
      }

      &.desktop {
        @apply hidden md:flex relative;

        .charity-badge {
          @apply absolute top-0 xl:right-[100px] lg:right-[50px] md:right-[10px];

          img {
            @apply max-w-[120px] lg:max-w-none;
          }
        }
      }
    }
  }

  .homepage__fees {
    .homepage__fees__footer p {
      @apply max-w-[500px] mx-auto mb-0;
    }
  }

  .homepage__fees-slider {
    .homepage__fees-slider__card {
      .homepage__fees-slider__image {
        @apply w-32 h-28;
      }
    }
  }

  .homepage__fees__container {
    @apply flex flex-col items-center justify-center py-16;

    .homepage__fees-cards {
      @apply hidden;

      @screen md {
        @apply flex flex-row justify-center my-20 w-4/5;
      }

      .homepage__fee-card {
        @apply w-1/4 text-center mx-auto flex flex-col items-center justify-between;

        h3 {
          @apply md:text-3xl font-sans mb-0;
        }

        svg {
          @apply w-1/2 h-1/3;
        }

        .homepage__fee-card__image {
          @apply bg-cover w-full h-40;
        }
      }
    }
  }

  .homepage__representation-process {
    @apply bg-gray-lighter;
    .homepage__representation-process__container {
      @apply flex flex-col items-center justify-between py-16 bg-gray-lighter;

      .homepage__representation-process__flow {
        @apply flex flex-col items-center my-20;

        @screen md {
          @apply flex-row w-4/5;
        }

        .homepage__representation-process__single-arrow {
          transform: rotate(90deg);
          @apply w-8 h-8 bg-no-repeat bg-contain bg-center;

          @screen md {
            transform: rotate(0deg);

            @apply mb-32;
          }
        }

        .homepage__representation-process__double-arrow {
          background-image: url('/images/double-arrow.png');
          transform: rotate(90deg);

          @apply w-20 h-32 bg-no-repeat bg-contain bg-center;

          @screen md {
            transform: rotate(0deg);

            @apply mb-32;
          }
        }

        .homepage__representation-process__card {
          @apply flex-col flex w-52 mx-2 text-center items-center relative;
        }

        .homepage__representation-fee {
          @apply flex;

          @screen md {
            @apply flex-col w-1/3 mb-32;
          }

          .homepage__representation-fee__card {
            @apply flex flex-col items-center w-24 mx-4;

            @screen md {
              @apply flex-row ml-4 my-5 mr-0  w-full text-left my-2;

              p {
                @apply w-3/4 my-0 ml-8;
              }
            }
          }
        }
      }
    }
  }

  .homepage__why__container {
    @apply container pt-16 md:pt-14;
  }

  .homepage__tools {
    .homepage__tools__award {
      background-image: url('/images/award.png');

      @apply w-full h-48 bg-contain bg-no-repeat bg-center mt-12;

      @screen md {
        @apply h-[20rem];
      }
    }

    .homepage__tools__container {
      @apply flex flex-col items-center justify-center pb-16 md:pb-14 container;

      h2 {
        @apply md:text-5xl;
      }

      .subtitle_1,
      .subtitle_1 p {
        @apply mt-0 max-w-[550px] m-auto;
      }

      p {
        > span {
          @apply text-base md:text-[19px] text-black md:block;
        }
      }

      span {
        @apply text-main;
      }

      .homepage__tools__next-icon {
        @apply md:w-[1.375rem];
      }

      .homepage__tools__img {
        @apply bg-contain bg-no-repeat w-full h-[7.5rem] md:h-[16rem] bg-center mt-10 md:mt-20 mb-3;
      }

      .homepage__tools__virtual-link,
      .homepage__tools__virtual-link a {
        @apply font-sans font-semibold;
      }

      .homepage__tools__virtual-link a {
        @extend h3;
      }

      .homepage__tools__vl {
        @apply mt-4 md:mt-11 mb-0 md:mb-6;

        p {
          @apply m-0;

          span {
            @apply text-black md:block;
          }
        }
        .homepage__tools__vl__text p {
          @apply mb-0 max-w-[550px] m-auto;
        }
      }
    }
  }

  .homepage__tools-new {
    @apply bg-[#282828] py-10 mt-16;
    &__button {
      @apply mt-9 capitalize text-base text-white font-normal tracking-[0.5px] bg-[#3576D9] p-3 rounded-full max-w-[315px] w-full block text-center mx-auto hover:opacity-70 hover:no-underline;
    }
    &__info {
      @apply text-center mx-auto font-sans text-4xl font-bold text-white mt-10  mb-4 tracking-[0.5px];
    }
    &__text {
      @apply max-w-[456px] sm:max-w-[670px] xl:max-w-[850px] text-center mx-auto text-white my-0 text-base  tracking-[0.5px] font-normal font-sans;
    }
  }

  .homepage__clients {
    @apply bg-gray-lighter;

    a {
      @apply no-underline;
    }

    .homepage__clients__container {
      @apply md:flex flex-col items-center justify-between py-16  md:h-[60rem] container;

      h2 {
        @apply mt-0 md:mt-10;
      }

      .homepage__clients__image {
        @apply bg-contain bg-no-repeat w-full h-[18rem] md:h-[26rem] bg-center;
      }

      .homepage__clients__contact-us {
        a {
          @apply mb-8;
        }
      }

      .homepage__clients__about-us {
        h2 {
          @apply max-w-[555px] font-sans mx-auto;
        }

        .subtitle_1,
        .subtitle_1 p {
          @apply max-w-[550px] font-sans mx-auto mt-0;
        }
      }

      .homepage__clients__about-us,
      .homepage__clients__contact-us {
        @apply flex flex-col items-center justify-center;

        p {
          @apply max-w-[550px] text-[19px] mx-auto;
        }

        .homepage__clients__contact-us__text {
          @apply mb-0;
        }
      }
    }
  }

  .homepage__team {
    .homepage__team__container {
      h2 {
        @apply mt-0 md:mt-10;
      }

      .subtitle_1 {
        @apply mt-0 max-w-[280px] m-auto;
      }

      @apply flex flex-col items-center justify-center py-16;

      .homepage__team__slider {
        @apply w-full;

        a {
          @apply text-[#454545] font-medium;
        }
      }
    }
  }
}

.homepage__bbc {
  p {
    @apply text-base md:text-[21px] font-semibold italic;
  }
}

.homepage__reviews {
  @apply bg-gray-lighter relative;

  > a img {
    @apply absolute right-0 md:right-[18%] top-[-50px] md:top-[-40px] max-w-[103px] md:max-w-full;
  }

  .reviews__container {
    @apply pb-0;

    .reviews__stars {
      @apply max-w-[200px] md:max-w-[370px];
    }

    .reviews__container__footer-text {
      @apply hidden;
    }

    h2 {
      @apply mb-1 mt-5;

      p {
        @apply text-[22px] md:text-[36px] font-semibold my-0 leading-[28px] md:leading-[70px] tracking-[-1.1px] md:tracking-[-2.4px];
      }

      a {
        @apply font-sans text-[22px] md:text-[48px] font-semibold mt-6 leading-[28px] md:leading-[70px] tracking-[-1.1px] md:tracking-[-2.4px] pointer-events-none md:pointer-events-auto;
      }
    }

    .subtitle_1 {
      p {
        @apply mb-0 md:mb-4 max-w-[300px] md:max-w-[100%];
      }
    }

    .custom__reviews__section {
      @apply pt-[30px];
    }
  }

  @screen md {
    @apply mt-0;
  }

  .homepage__awards {
    @apply mb-[53px] md:mb-[60px];

    .charity-badge {
      @apply block md:hidden mb-2;

      img {
        @apply max-w-[120px];
      }
    }

    h3 {
      @apply text-center mt-[10px] mb-[40px] md:mb-[74px];
    }

    .homepage__awards__desktop {
      @apply hidden md:flex max-w-4xl text-center m-auto justify-between;

      .homepage__awards__desktop__left {
        @apply flex my-auto;

        .award-image-left {
          @apply h-full object-contain;
        }

        .subtitle_1 {
          @apply text-left ml-[22px] max-w-[220px] my-auto leading-[30px];
        }
      }

      .homepage__awards__desktop__right {
        .subtitle_1 {
          @apply text-left mt-2 m-0 leading-[25px] font-semibold max-w-[267px];
        }
      }
    }
  }

  .homepage__awards__mobile {
    @apply block md:hidden;

    .homepage__awards__mobile__left {
      @apply mb-[45px];

      .subtitle_1 {
        @apply text-center mt-[14px] m-auto font-semibold max-w-[234px];
      }
    }

    .homepage__awards__mobile__right {
      .subtitle_1 {
        @apply text-center max-w-[295px] mt-[14px] m-auto;
      }
    }
  }

  .homepage__reviews__bottom_paragraph {
    @apply pb-[26px] md:pb-[48px] max-w-[294px] md:max-w-[550px] m-auto;
  }
}
