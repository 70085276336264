.dihac {

  .dihac-banner {

    .dihac-banner-image {
      @apply h-[310px] md:h-[530px];
      background-size: 100%;
      object-fit: cover;

      &.desktop {
        @apply hidden md:block;
      }

      &.mobile {
        @apply block md:hidden;
      }

      .dihac-banner__container {
        @apply w-full h-full px-4 mx-auto flex justify-center flex-col;

        @screen md {
          @apply w-auto max-w-[90%] px-0;
        }

        .dihac-banner__container__title {
          @apply mt-0 mb-4 text-2xl md:text-6xl font-medium text-white max-w-[180px] md:max-w-[480px];
        }

        .subtitle_1 {
          @apply mt-0 mb-[20px] md:mb-8 text-xl md:text-3xl font-normal text-white max-w-[188px] md:max-w-full;
        }

        .dihac-banner__cta {
          .button {
            @apply rounded-[28px] text-base md:text-[21px] font-semibold capitalize no-underline w-[180px] md:w-[250px] block text-center;
          }
        }
      }
    }
  }

  .desc {
    p {
      border-bottom: 1px solid #8F8F8F;
      @apply py-3 md:py-10 text-[#454545] font-semibold text-center text-[13px] md:text-[21px] px-4 m-0;

      strong {
        @apply text-[#6a6a6a];
      }
    }
  }

  .video-section {
    @apply bg-[#F0F0F0] py-[80px] mb-[80px];
  }

  .youtube {
    @apply container max-w-[1050px] mx-auto;

    iframe {
      @apply h-[180px] md:h-[600px];
    }
  }

  .dihac__case-details {
    @apply bg-[#ebf6fd] pt-[50px] pb-[50px] md:pt-[90px] md:pb-[67px];

    h3 {
      @apply mt-0 mb-[10px] text-center text-[#454545] font-semibold text-[22px] md:text-[47px];
    }

    p {
      @apply m-0 text-center text-base md:text-[19px] font-normal max-w-[600px] mx-auto;
    }

    .dihac__case-details__col {
      @apply flex flex-col md:flex-row gap-[50px] md:gap-[100px] container mt-[66px] md:mt-[76px] mb-[76px] md:mb-[96px];

      .dihac__case-details__col__left {
        @apply max-w-full md:max-w-[30%];

        p {
          @apply max-w-[305px];
        }
      }

      .dihac__case-details__col__right {
        @apply max-w-full md:max-w-[70%];

        p {
          @apply max-w-[570px];
        }
      }

      p {
        @apply text-[13px] md:text-[17px] font-normal text-left mx-0;

        b {
          @apply text-[17px] md:text-[23px] md:mb-4 mb-2 block font-semibold;
        }
      }

      img {
        @apply w-full md:max-h-[265px] object-contain mb-6 md:mb-[34px];

        &.mobile {
          @apply block md:hidden;
        }

        &.desktop {
          @apply hidden md:block;
        }
      }
    }
  }

  .dihac_grapple-section {
    @apply bg-[#f2f2f2] py-[50px] md:py-[80px];

    .dihac_grapple-section__col {
      @apply container flex md:flex-row flex-col gap-6 md:gap-[50px] justify-center items-center max-w-[250px] md:max-w-full;

      p {
        @apply text-[15px] md:text-[21px] mt-0 mb-3 text-center md:text-left font-normal;

        b {
          @apply text-[17px] md:text-[23px] font-semibold;
        }
      }

      .dihac-banner__cta {
        .button {
          text-transform: none;
          @apply mt-[25px] rounded-[28px] text-base md:text-[21px] font-medium  no-underline w-[180px] md:w-[250px] block text-center mx-auto md:mx-0;
        }
      }
    }
  }

  .dihac__why-section {
    @apply container text-center;

    h3 {
      @apply text-[20px] md:text-[36px] mt-0 mb-[60px] md:mb-[100px];
    }

    .dihac__why-section__col {
      @apply flex flex-col md:flex-row gap-[65px] md:gap-[80px] mb-[70px] justify-center;

      img {
        @apply max-w-[104px] md:max-w-[150px] w-full mb-6 md:mb-[40px];
      }

      p {
        @apply  mb-3 md:mb-4 mt-0 text-base md:text-[19px] max-w-[300px] mx-auto;

        b {
          @apply text-[19px] md:text-[23px] block font-semibold;
        }
      }
    }

    .dihac-banner__cta {
      @apply flex justify-center;

      .button {
        @apply rounded-[28px] text-base md:text-[21px] font-medium capitalize no-underline w-[180px] md:w-[250px] text-center mx-auto md:mx-0 block mb-24 md:mb-0;
      }
    }
  }
}
