.book-your-consultation {
  .page {
    & > h1 {
      @apply mb-0;
    }
  }
}

.section__video {
  @apply mt-5;
  @screen xl {
    @apply mt-[37px];
  }
  .section__video__wrapper {
    //  pb-[56.25%]
    @apply relative mb-10 mx-0;

    @screen xl {
      @apply mb-[60px];
    }

    .yt-lite {
      @apply aspect-video w-full relative bg-no-repeat bg-center bg-cover flex items-center justify-center;

      @screen xl {
        @apply m-auto max-w-[65.89dvw];
      }

      .lty-playbtn {
        background: unset;

        @apply absolute w-14 h-14 border-0 cursor-pointer bg-[#0096f4] flex items-center justify-center rounded-full shadow-lg;

        &:after {
          @apply w-0 h-0 content-[''] absolute opacity-100 ml-0.5 border-r-0 border-solid border-l-[15px] border-l-white border-y-[10px] border-y-transparent;
        }
      }
    }
  }
  iframe {
    @apply absolute inset-0 w-full h-full mx-auto bg-white;

    // @screen xl {
    //   @apply max-w-[650px] max-h-[366px];
    // }
  }
}
