.section__banner {
  @apply pt-9 md:pt-20 text-center;

  .section__banner__container {
    background-size: 100%;

    @apply flex h-[360px] flex-col justify-start items-center bg-bottom bg-no-repeat;

    @screen md {
      @apply h-[520px];
    }

    h1 {
      @apply mb-0 mt-0 md:mt-[50px] max-w-[300px] md:max-w-[900px];
    }

    .section__banner__cta {
      @apply mt-5 md:mt-36 flex flex-col;

      @screen md {
        @apply mt-28;
      }

      a {
        @apply text-sm md:text-lg no-underline font-semibold;
      }

      small {
        @apply text-gray-light mt-4;
      }
    }

    &.mobile {
      @apply flex md:hidden
    }

    &.desktop {
      @apply hidden md:flex
    }
  }
}
