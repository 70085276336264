.section__blog__banner {
  @apply py-16;
  &__container {
    @apply container;
  }
  &__title {
    @apply text-center;
    h1 {
      @apply m-0;
    }
    p {
      font-size: calc(16px + (22 - 16) * ((100vw - 280px) / (1920 - 280)));
      @apply mb-0 font-medium leading-[1.3];
    }
  }
}

.section__blog__list {
  @apply pb-24 block;
  &__container {
    @apply container;
  }
  &__filter-wrap {
    @apply mb-[22px] md:mb-8 flex flex-wrap justify-between gap-y-3 flex-col md:flex-row;

    &__text {
      @apply pt-1.5;
      label {
        @apply text-xs font-semibold font-sans uppercase text-dark-text;
      }
    }
    &__filter-list {
      @apply md:max-w-[calc(100%_-_250px)] md:ml-auto;
      ul {
        @apply flex flex-wrap gap-[5px] p-0 m-0 list-none;
      }
      li {
        span {
          @apply cursor-pointer inline-block px-3 py-1.5 leading-none font-semibold tracking-normal rounded-full text-[#83838f] hover:text-[#f0f0f5]  uppercase text-[8px] md:text-[10px] bg-[#f0f0f5] hover:bg-[#83838f] transition-all duration-300 select-none;
        }
        &.active {
          span {
            @apply text-[#f0f0f5] bg-[#83838f];
          }
        }
      }
    }
  }
}

.section__blog__item__list {
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-10 md:gap-y-12 xl:gap-y-20 2xl:gap-y-28;
}
.section__blog__item {
  @apply cursor-pointer max-w-[400px] md:max-w-[unset] mx-auto md:mx-0 hover:no-underline hover:opacity-80;
  &__img-wrap {
    @apply h-[210px] md:rounded-[5px] overflow-hidden mb-[15px];
    img {
      @apply w-full h-full object-cover;
    }
  }
  &__content-wrap {
    @apply flex flex-col gap-y-3;
  }

  &__title {
    @apply m-0 font-serif text-black text-[22px] leading-[1.5];
  }
  &__tag-wrap {
    @apply flex flex-wrap gap-2;
  }
}
section .blog__desc,
section .blog__desc * {
  @apply m-0 text-black font-medium text-[15px] md:text-[19px];
}
section .blog__tag {
  @apply inline-block w-fit px-3 py-1.5 leading-none font-bold tracking-normal rounded-full text-primary uppercase text-[8px] md:text-[10px] bg-primary/10 h-fit;
}
section .blog__date {
  @apply text-sm font-semibold text-black font-sans;
}
// DETAILS STYLES

.blog__details {
  @apply mt-[30px];
  &__container {
    @apply max-w-[335px] sm:max-w-[80%] md:max-w-[930px] mx-auto relative;
  }
  &__image {
    @apply lg:max-w-[calc(100%_-_130px)] h-[176px] sm:h-[250px] md:h-[370px] xl:h-[419px] relative z-10 mb-5 flex justify-center text-center px-5 md:px-7 pt-6 md:pt-10 lg:pt-14 transition-all duration-300;
    &::before {
      @apply bg-black absolute inset-0 opacity-50 content-[''] z-10;
    }
    img {
      @apply w-full h-full object-cover absolute top-0 left-0 -z-10;
    }
  }
  &__title {
    @apply relative z-10 text-white m-0 h-fit;
  }
}
.blog__details__data {
  @apply lg:flex lg:justify-between;
}

.blog__details__share {
  @apply mt-10 lg:mt-0 h-fit lg:sticky max-w-[80px] right-[-120px] top-[130px] flex lg:flex-col gap-4 z-10 items-center;
  label {
    @apply text-[15px] text-[#c0c0c0] font-medium block;
  }
  ul {
    @apply list-none flex lg:flex-col gap-4 p-0 m-0;
  }
  li {
    a {
      @apply w-7 h-7 block;
      img {
        @apply w-full h-full object-contain opacity-60;
      }
    }
  }
}
.blog__details__content__editor {
  // @apply mt-0.5;

  & :is(h2, h3, h4, h5, h6) {
    @apply font-semibold tracking-normal mb-3.5 mt-10 leading-[1.4] first:mt-0;
  }
  h2 {
    font-size: calc(22px + (28 - 22) * ((100vw - 280px) / (1920 - 280)));
  }
  h3 {
    font-size: calc(19px + (22 - 19) * ((100vw - 280px) / (1920 - 280)));
  }
  h4 {
    font-size: calc(17px + (19 - 17) * ((100vw - 280px) / (1920 - 280)));
  }
  h5 {
    font-size: calc(14px + (17 - 14) * ((100vw - 280px) / (1920 - 280)));
  }
  h6 {
    font-size: calc(12px + (15 - 12) * ((100vw - 280px) / (1920 - 280)));
  }
  ul,
  ol {
    @apply list-outside pl-5 mb-3.5 mt-10;
  }
  p,
  li {
    @apply mb-1.5 mt-0;
  }
  p,
  li,
  a,
  i,
  strong {
    font-size: calc(14px + (17 - 14) * ((100vw - 280px) / (1920 - 280)));
    @apply font-medium;
  }
  strong {
    @apply font-bold;
  }
  blockquote {
    @apply pl-10 border-0 border-l-4 border-l-main border-solid ml-1 py-3.5 text-lg font-semibold italic leading-[1.3];
  }
  blockquote * {
    @apply m-0  text-lg font-semibold italic leading-[1.3];
  }
}
.blog__details__content {
  @apply flex flex-col lg:max-w-[calc(100%_-_130px)];
  &__tag {
    @apply mb-4;
  }
  &__date {
    @apply mb-2.5;
  }
}
