.section-review {
  @apply bg-gray-lighter;

  .section-review__intro {
    @apply container text-center py-8 mt-[35px] md:mt-16;

    p {
      @apply max-w-[980px] mx-auto;
    }

    .section-review__intro__awards__img {
      background-image: url('/images/law-society-and-modern-law-awards-mobile.svg');

      @apply w-full h-[18rem] bg-contain bg-no-repeat bg-center mt-12;

      @screen md {
        background-image: url('/images/law-society-and-modern-law-awards-desktop.svg');
        @apply h-[10.5rem];
      }
    }
  }
}


.section__customer-reviews {
  @apply font-sans max-w-[860px] m-auto mt-[74px] md:mt-[120px] md:pb-6;

  .section__customer-reviews__header {
    @apply text-center my-20;

    h3 {
      @apply font-sans;
    }
  }

  .section__customer-reviews__single {
    @apply mb-[40px] md:mb-[50px];

    .section__customer-reviews__single__header,
    .section__customer-reviews__single__footer {
      @apply flex justify-between items-center;
    }

    .section__customer-reviews__single__header {
      @apply m-0;
      strong {
        @apply text-lg md:text-[1.75rem] tracking-[-0.72px] md:tracking-[-1.12px] mr-8;
      }
      small {
        @apply text-xs md:text-[15px] font-medium text-[#5a5a5a] tracking-[-0.48px] md:tracking-[-0.6px];
      }
    }

    .section__customer-reviews__single__footer {
      a {
        line-height: 0;
      }
    }

    .section__customer-reviews__single__body {
      @apply mt-[18px] md:mt-5 mb-7 md:mb-8 text-[16px] md:text-[19px] leading-[21px] md:leading-[26px] tracking-[-0.14px] md:tracking-[-0.19px] text-[#5a5a5a] font-[400];
    }
  }
}
