html {
  scroll-behavior: smooth;
}

.no-win-no-fee {

  img {
    @apply max-w-full h-auto;
  }

  h3 {
    @apply m-0 text-2xl md:text-4xl;

    p {
      @apply m-0 text-2xl md:text-4xl;
    }
  }

  p {
    @apply text-xl;
  }

  .no-win-no-fee__eligiblity {
    @apply bg-[#f7f7f7] py-20;

    .no-win-no-fee__eligiblity__container {
      @apply container;

      .no-win-no-fee__eligiblity__container__inner {
        @apply max-w-[1000px] block md:flex gap-16;

        > div {
          @apply max-w-[600px];
        }

        img {
          @apply  md:max-w-[500px] w-full max-w-full md:w-auto;
        }

        h3 {
          @apply mb-7 md:leading-[48px] font-semibold md:max-w-[350px];
        }

        .sidebar__cdf {
          @apply mt-14 md:mt-0 border-[#ddd] border-[1px] rounded-md border-solid bg-white text-center max-w-full w-full h-max;

          h5 {
            @apply pt-8 text-2xl;
          }

          .cdf.cal {

            .cdf__field__wrapper {
              p {
                @apply text-left my-4;
              }
            }

            select, input {
              outline: unset;
              @apply h-10 rounded-[5px] w-full text-[15px] md:text-[17px] font-sans text-[#727272] pl-[12px] border-[1px] border-[#dddddd] border-solid bg-[#f7f7f7];
            }
            .cdf__field__error {
              @apply text-left block;
            }
          }

          .cdf {
            @apply border-0 bg-white;

            .status-disc {
              @apply text-[15px] text-[#454545] leading-6 text-left;
            }

            h5 {
              @apply hidden;

              &.status {
                @apply block text-[21px] font-semibold m-0 leading-5 p-0;
              }
            }

            .cdf {
              @apply p-0;

              .cdf__form__second-step,
              .cdf__form__third-step,
              .cdf__second-step__fields {
                @apply mt-0;
              }
            }
          }
        }
      }
    }
  }

  .no-win-no-fee-banner-image {
    @apply h-[250px] md:h-[600px];
      background-size: 100%;
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &.desktop {
        @apply hidden md:block;
      }

      &.mobile {
        @apply block md:hidden;
      }

    .no-win-no-fee-banner__container {
      @apply text-center flex justify-center h-full items-center flex-col;

      h2 {
        @apply text-white font-serif m-0 font-medium text-[36px] md:text-[62px] md:leading-[72px] max-w-[350px] md:max-w-[780px];
      }

      p {
        @apply text-white font-normal w-[350px] md:w-full text-xl;
      }
    }
  }

  .no-win-no-fee__explanation {
    @apply container mt-12 mb-12;

    .no-win-no-fee__explanation__link {
      @apply md:max-w-[30%] bg-[#00a7ff] p-16 rounded-xl mt-8 md:mt-0;

      p {
        @apply font-medium m-0 text-xl;

        a {
          @apply text-white underline leading-7 mt-2 block text-xl;
        }
      }
    }
  }

  .no-win-no-fee__getstarted {
    @apply container;
  }

  .no-win-no-fee__getstarted__link {
    grid-template-columns: 1fr 2fr;
    @apply max-w-[550px] m-auto rounded-[10px] grid text-center text-white items-center bg-[#5a83d1];

    img {
      border-radius: 10px 0px 0px 10px;
      @apply w-[150px] md:w-[180px] object-cover m-[-1px] h-full;
    }

    > div {
      @apply p-3 md:p-[30px];

      p {
        @apply m-0 text-lg md:text-xl;

        a {
          @apply text-sm md:text-base block leading-5 text-white;
        }
      }
    }
  }

  .no-win-no-fee__more-explanation,
  .no-win-no-fee__more-faq {
    @apply container my-12;

    h3 {
      @apply mt-7;
    }
  }

  .homepage__reviews {
    @apply text-center pt-14 mt-16 md:-mb-24;

    h2, p {
      @apply m-0;
    }

    h2 {
      @apply text-3xl md:text-5xl mb-4;
    }

    p {
      @apply max-w-xs m-auto md:max-w-full text-xl;
    }

    h3 {
      @apply md:my-8 mt-4 mb-0;
    }
  }
}
