.cta {
  @apply mt-12 relative border-solid border-0 md:border-b-2 border-gray-cdf;
  
  .cta-banner {
    @apply bg-top h-[10rem] md:h-[20rem] bg-no-repeat bg-cover w-full flex justify-center pt-4 md:pt-[69px] bg-[#282828] rounded-t-lg;
    background-image: radial-gradient(#346CCC 43%, #174088);

    .cta-banner__container {
      @apply text-center max-w-[520px];

      h6 {
        // @apply font-medium text-base md:text-[28px] text-white mt-0 mb-4 md:mb-10;
        @apply text-center mx-auto font-sans text-2xl font-bold text-white  mb-4  tracking-[0.5px];
      }

      p {
        @apply max-w-[456px] sm:max-w-[650px] xl:max-w-[750px] text-center mx-auto text-white my-0 text-base  tracking-[0.5px] font-normal font-sans mb-4 px-4;
      }

      .button {
        // @apply font-medium text-[11px] md:text-base px-6 py-3 md:px-10 md:py-4;
        @apply capitalize text-base text-[#313030] font-semibold tracking-[0.5px] bg-white p-3 rounded-full max-w-[280px] w-full block text-center mx-auto hover:opacity-70 hover:no-underline;
      }

      .cta-banner__review__section {
        @apply absolute left-0 right-0 md:mt-10;

        .custom__reviews__section {
          @apply mt-[30px];

          .custom__reviews__section__card_wrapper {
            @apply rounded-[6px] md:rounded-[7px] max-w-[210px] md:max-w-[253px] px-[17px] pt-[17px] pb-[24px] md:p-[24px] mr-[13px] md:mr-[14px];
            box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.06);

            @screen md {
              box-shadow: 0 11px 11px 0 rgba(0, 0, 0, 0.07);
            }

            .subtitle_1 {
              @apply text-[14px] md:text-[18px];

              p {
                @apply text-[13px] md:text-[17px] leading-[17px] md:leading-[24px];
              }
            }

            .custom__reviews__section__verified__image {
              @apply w-[14px] h-[14px] md:w-[16px] md:h-[16px];
            }

            .custom__reviews__section__verified_text {
              @apply text-[12px] md:text-[14px];
            }
          }
        }
      }

      .reviews__container {
        @apply p-0 mt-2 md:mt-0;

        .reviews__stars {
          @apply hidden;
        }

        .R-ReviewsList {
          @apply pb-2 pt-5;
        }

        .custom__reviews__section {
          @apply hidden;
        }
      }
    }

    .reviews-io {
      @apply mt-0 md:mt-16 overflow-x-hidden;

      .CarouselWidget__footer {
        @apply mb-5;
      }

      .R-ReviewsList {
        .R-ReviewsList__item {
          @apply text-[0.75rem] max-w-[255px] pl-0;

          @screen md {
            @apply text-xs pl-[10px];
          }

          .item__inner {
            box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.02);

            @apply font-sans;

            .cssVar-badgeElement__text {
              @apply text-xs;
            }

            .R-ReviewsList__item--body {
              @apply text-sm;
            }
          }
        }
      }
    }
  }

  .cta-banner.description_class {
    @apply pt-4 md:pt-12 h-[14rem] md:h-[22rem];
    .cta-banner__container {
      @apply text-center;
      h6 {
        @apply mb-2;
      }
      p {
        @apply mb-4 sm:text-base text-sm;
      }
      p.custom__reviews__section__verified_text {
        @apply mb-0 mx-0;
      }
      .subtitle_1 p {
        @apply px-0 text-left;
      }
    }
  }
  .cta-review {
    @apply mt-40 md:mt-44;

    .reviews__container {
      @apply p-0 bg-transparent;

      .reviews__stars {
        @apply w-[60%] md:w-[100%] h-10 md:h-12;
      }

      .reviews-io {
        @apply hidden;
      }

      .custom__reviews__section {
        @apply hidden;
      }

      h3 {
        @apply mt-4 mb-12 md:mt-3 md:mb-10 font-sans font-[500] text-[19px] md:text-[23px] text-black;

        a {
          @apply font-sans text-[19px] md:text-[23px] font-[500];
        }
      }
    }
  }
}
