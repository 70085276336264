.cta-tactics_banner{
    @apply mb-8;
    border: 0 !important;
    .cta-banner{
        @apply flex items-center flex-col justify-center rounded-lg;
        padding-top:0 !important;
        height: 270px !important;

        h6{
            @apply mt-0;
        }
    }
}