.section__text {
  @screen md {
    @apply my-24;
  }

  p {

    img {
      @apply w-[100%] h-auto;

      @screen md {
        @apply w-auto;
      }
    }
  }
}

.section__text,
.section__helpful-guides {

  h2 {
    @apply tracking-[-0.84px];

    @screen md {
      @apply text-[1.75rem] leading-[37px];
    }
  }

  h3 {
    @apply font-semibold mb-0 mt-10 font-sans tracking-[-0.69px];

    @screen md {
      @apply text-[23px] leading-[30px];
    }
  }

  p, a, ul, li, span {
    letter-spacing: -0.38px;
    word-break: break-word;

    @apply font-serif;
  }

  p:last-child {
    @apply mb-0;
  }
}

.section__call-to-action-fullwidth {

  p, a {
    @apply font-sans;
  }
}

.section__video {
  @apply mt-6;
}

.section__helpful-guides {
  @apply hidden md:block;

  ul {
    @apply mt-[28px];

    li {
      @apply pb-[13px];

      a {
        @apply font-[600] font-sans leading-[22px];
      }
    }
  }
}
