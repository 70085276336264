.team-slider {
  @apply w-full;

  @screen md {
    @apply w-4/6  mx-auto;
  }

  .swiper-button-prev,
  .swiper-button-next {

    &::after {
      @apply hidden;
    }
  }

  .swiper-button-prev {

    @screen md {
      @apply left-0 bg-no-repeat bg-center rounded-[50%] border-[#ffffff] pt-[18.2px] pb-[18.1px] pr-[23px] pl-[22.7px] bg-[url('/images/team-scroll-arrow-left.svg')];
      box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.08);
     }
  }

  .swiper-button-next {

    @screen md {
      @apply right-0 bg-no-repeat bg-center rounded-[50%] border-[#ffffff] pt-[18.2px] pb-[18.1px] pr-[23px] pl-[22.7px] bg-[url('/images/team-scroll-arrow-right.svg')];
      box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .swiper-slide {
    @apply mb-[3rem] md:mb-[70px];
  }

  .team-slider__lawyer {
    @apply w-[260px] md:h-[500px] md:h-[40rem] md:w-[300px] flex text-center flex-col m-auto;

    a {
      @apply underline;
    }

    .team-slider__lawyer__card {
      @apply h-[460px] md:h-[515px] w-full flex flex-col p-4 rounded-xl my-8 shadow-xl pt-[1rem] md:pt-[23px];

      .team-slider__lawyer__portrait  {
        @apply bg-no-repeat bg-center;
      }

      .team-slider__lawyer__portrait {
        @apply w-28 h-28 self-center bg-contain;
      }

      .team-slider__lawyer__info {
        @apply mb-[18px] md:mb-[30px];

        h3 {
          @extend h5;
          @apply mb-0 mt-[14px] md:mt-[18px];
        }

        .caption {
          @apply text-[#727272];
        }
      }

      .subtitle_2 {
        @apply text-[#5A5A5A] max-w-[335px] md:max-w-[250px] text-[15px] md:text-[16px] my-0 mx-auto;
      }
    }

    .team-slider__lawyer__link {
      @apply flex items-center mx-auto;

      svg {
        transform: rotate(270deg);
      }
    }
  }
}
