.cdf {
  @apply bg-gray-lighter p-12 rounded-xl my-12 font-sans border-[1px] border-solid border-[#dddddd];

  @screen md {
    @apply my-36;
  }

  input {
    @apply text-[15px] p-2 text-[#727272];
  }

  h3 {
    @apply font-sans;
  }

  small {
    @apply text-center mt-8;
  }

  @screen lg {
    @apply p-20;
  }

  span {
    @apply text-[#00a7ff] leading-none;
  }

  .cdf__form {
    @apply flex flex-col justify-center;

    button {
      @apply w-44 text-center mx-auto mt-8;
    }

    .cdf__field__required {
      @apply mt-5 text-[13px] md:text-[15px] font-['Helvetica'] text-[#727272] font-normal;
    }

    .cdf__field__footer-text {
      @apply text-center mt-[10px] text-[12px] md:text-[15px] font-['Helvetica'] font-normal text-[#5a5a5a];
    }

    .cdf__field__footer-error {
      @apply text-center mt-[2px] mb-[2px] text-[12px] md:text-[15px] font-['Helvetica'] font-normal text-[#5a5a5a];
    }

    p {
      @apply font-semibold text-sm;
    }

    .text-area-speech {
      @apply relative;

      button {
        width: 3rem !important;
        @apply absolute right-0 w-12 h-12 bottom-0 bg-transparent border-0 m-2 block z-50;

        img {
          @apply cursor-pointer w-full h-full;
        }
      }
    }

    .cdf__form__call-taken-by {
      @apply w-full text-center my-12;

      select {
        @apply w-40 h-12 rounded-md border-gray px-4;
      }
    }

    .cdf__form__first-step {
      @apply flex flex-col;

      @screen md {
        @apply mx-auto;
      }

      @screen lg {
        @apply mx-0;
      }

      h5 {
        @apply font-sans mt-0 mb-[36px] text-center md:text-left font-bold;
      }

      .cdf__first-step__fields {
        @apply flex flex-col justify-between;

        @screen md {
          @apply flex-wrap flex-wrap flex-row;
        }

        @screen lg {
          @apply flex-row;
        }

        .cdf__field__wrapper {
          @apply flex flex-col mb-4;

          @screen md {
            @apply w-[45%] mb-0;
          }

          @screen lg {
            @apply w-1/4 mb-0;
          }

          p {
            @apply mt-0 mb-4 text-[14px] font-bold font-['Helvetica'] tracking-[-0.13px] leading-[22px];
          }

          input {
            @apply h-10 rounded-[5px] border-[1px] border-[#dddddd] border-solid;

            @screen lg {
              @apply w-[90%];
            }
          }

          textarea {
            @apply w-full rounded-[5px] font-sans border-[1px] border-[#dddddd] border-solid;
          }
        }
      }
    }

    .cdf__form__second-step {
      @apply flex flex-col mt-8;

      @screen md {
        @apply mx-auto;
      }

      @screen md {
        @apply mx-0;
      }

      @screen lg {
        @apply mx-0;
      }

      h5 {
        @apply font-sans mt-0 mb-[36px] text-center md:text-left font-bold;
      }

      .cdf__second-step__fields {
        @apply flex  flex-col;

        input {
          @apply h-10 rounded-[5px] border-[1px] border-[#dddddd] border-solid;

          @screen lg {
            @apply w-[90%];
          }
        }

        .cdf__field__wrapper {

          p {
            @apply mt-0 mb-4 text-[14px] tracking-[-0.13px] font-bold h-auto md:h-12 font-['Helvetica'] leading-[24px];
          }
        }

        .cdf__second-step__select {
          @apply flex flex-row justify-between items-center flex-col;

          @screen md {
            @apply flex-wrap flex-row mx-0;
          }

          @screen lg {
            @apply flex-row;
          }

          .cdf__select-field__wrapper {
            @apply flex flex-col justify-between w-full mb-6;

            .cdf__select-field__wrapper__inner {
              @apply flex md:w-[90%] justify-between relative;
            }

            @screen md {
              @apply w-[45%] mb-0;
            }

            @screen lg {
              @apply w-1/5;
            }

            p {
              @apply mt-0 mb-4 text-[14px] tracking-[-0.13px] font-bold h-auto md:h-12 font-['Helvetica'] leading-[24px];
            }

            select {
              outline: unset;
              @apply h-10 rounded-[5px] w-full text-[15px] md:text-[17px] font-sans text-[#727272] pl-[12px] border-[1px] border-[#dddddd] border-solid;

              @screen lg {
                @apply w-[90%];
              }
            }
          }
        }
        .cdf__tooltip {
          @apply absolute z-10 right-[10px] top-[38px];

          .cdf__tooltip__inner {
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
            @apply bg-[#30a9fd] p-4 rounded-tr-[0px] rounded-lg w-[240px] relative;

            &:after {
              content: "";
              border-bottom: 13px solid #30a9fd;
              border-left: 23px solid transparent;
              border-end-start-radius: 0px;
              border-start-end-radius: 5px;
              border-start-start-radius: 36px;

              @apply absolute top-[-12px] right-0;
            }

            .cdf__tooltip__inner__title {
              @apply mb-[5px] text-base font-semibold h-auto m-0 text-white;
            }

            .cdf__tooltip__inner__paragraph {
              @apply mb-[11px] text-base h-auto m-0 text-white font-normal;
            }

            .cdf__tooltip__inner__action {
              @apply font-medium text-base cursor-pointer h-auto m-0 text-white;
            }
          }
        }

      }

      .cdf__form__third-step {
        @apply w-full md:mt-10 md:pr-[22px];

        .cdf__form__third-step__inner {
          @apply flex justify-between relative;
        }

        p {
          @apply mt-0 mb-4 text-[13px] tracking-[-0.13px] md:text-[14px] font-bold font-['Helvetica'] leading-[24px];
        }

        textarea {
          outline: unset;
          @apply w-full rounded-[5px] text-[15px] p-2 text-[#727272] border-[1px] border-[#dddddd] border-solid;
        }
      }
    }
    .cdf__form__final-step {
      @apply mt-4;

      label {
        @apply flex gap-2 items-center mb-2;

        input {
          @apply w-5 h-5 m-0;
        }

        span {
          @apply text-black;
        }
      }
    }

    .footer-error {
      @apply block text-center;
    }
  }

  &.cdf3 {

    form {

      > div {
        @apply bg-white p-5 pb-11 rounded-lg mb-2;

        >label {
          @apply flex mb-8  gap-2 items-center;

          &:last-child {
            @apply mb-0;
          }

          span {
            @apply text-[#000] w-full;
          }

          input {
            @apply m-0 h-6 w-6;
          }
        }
      }
    }
  }
}

.cdf__field__error {
  @apply text-[#00a7ff] mt-2;
}

.cdf__field__wrapper {
  textarea {
    @apply w-full rounded-[5px] font-sans border-[1px] border-[#dddddd] border-solid leading-5 md:leading-6 p-3 text-sm;
  }
}


.loader {
  @apply h-[365px] flex items-center justify-center text-center flex-col;

  img {
    animation: animName 2s linear infinite;
  }

  p {
    @apply m-0 mt-7 font-semibold md:text-lg;
  }

  @keyframes animName {
    0% {
      transform: rotate(0deg);
    }

   100% {
      transform: rotate(360deg);
    }
   }
}

.submit-loader {
  max-height: 34px;
  margin-top: -6px;

  img {
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
