.guides-menu {
  @apply bg-gray z-30 absolute w-full font-sans pt-[30px] md:pt-12 md:pb-20 h-[100vh] pl-[18px] pr-[65px] md:pr-[0] md:pl-0;

  @screen md {
    @apply mt-[92px] overflow-y-auto;
  }

  .guides-menu__container {
    @apply flex flex-col w-full justify-center mx-auto mt-4 md:mt-0 md:gap-y-[22px];

    @screen md {
      @apply flex-row flex-wrap w-3/4 justify-between mx-auto;
    }

    a {
      @apply text-white;
    }

    .guides-menu__topic-container {
      @apply flex justify-between md:justify-start md:flex-col md:w-[25%];

      @screen md {
        @apply mb-6;
      }

      @media (max-width: 767px) {
        p {
          @apply text-[19px] leading-[16px] tracking-[-0.43px] mt-0 mb-[35px] font-[500];
        }
      }

      a {
        @apply my-1;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
