.guides {
  @apply container font-sans;

  h2 {
    @apply text-4xl mt-20;
  }

  .guides__main-cases {
    border-top: white 1px solid;
    border-bottom: white 1px solid;
    @apply bg-[#0096F4] h-16 flex justify-between items-center px-12;

    a {
      @apply text-white font-semibold;
    }
  }

  .guides__specific-cases {
    border-top: 1px solid;
    border-bottom: 1px solid;
    @apply border-main  h-16 flex justify-between items-center px-12;

    a {
      @apply font-semibold;
    }
  }
}
