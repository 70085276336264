.custom__reviews__section {
  @apply w-[100%] h-auto overflow-hidden mt-[10px] mb-[40px] md:mb-[70px];

  .custom__reviews__section__inner-wrapper-slider {
    @apply flex;
    animation: scroll 35s linear infinite;
    width: calc(100% * 6.7);


    @screen md {
      width: calc(100% * 14);
    }

    &:hover {
      animation-play-state: paused;
    }

    @keyframes scroll {
      0% { transform: translateX(0); }
      100% { transform: translateX(calc(-250px * 7))}
    }

    .custom__reviews__section__card_wrapper {
      @apply text-left bg-[#ffffff] mb-[25px] mr-[15px] md:mr-[25px] max-w-[269px] md:max-w-[342px] py-[26px] md:py-[36px] px-[27px] rounded-[7px] md:rounded-[8px];
      box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);

      @screen md {
        box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.08);
      }

      &:first-child {
        @apply ml-[25px];
      }

      .custom__reviews__section__verified_text {
        @apply text-[12px] md:text-[15px] my-0 text-[#7d7f7e] font-['Arial'] pl-1;
      }

      .custom__reviews__section__verified__image__wrapper {
        @apply flex items-center mb-[7px];

        .custom__reviews__section__verified__image {
          @apply w-[15px] h-[15px] md:w-[20px] md:h-[20px];
        }
      }

      .subtitle_1 {
        @apply text-[15px] md:text-[19px] text-[#0f1311] my-0 font-['Arial'];

        p {
          @apply my-0 text-[#484b4a] text-[14px] md:text-[18px] font-['Arial'] overflow-hidden leading-[20.8px] md:leading-[26px];
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

