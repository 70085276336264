.cdf-new3 {
  @apply max-w-[380px]  w-full inline-block  mx-auto  px-6 pt-4 pb-11 border border-[#ddd] rounded-md bg-[#f7f7f7] text-[#454545];

  .main-container {
    @apply text-center;

    h6 {
      @apply mt-0 mb-9  text-center text-[18px];
    }

    .form-control2 {
      @apply flex flex-col mb-4;

      label {
        @apply text-sm font-bold mb-1 mt-0 text-left;

        span {
          @apply text-[#00a7ff];
        }
      }

      div {
        @apply text-left text-[#00a7ff] italic text-base mt-2;
      }

      input {
        border: 1px solid #dddddd;
        @apply p-3 rounded-md   border-[#ddd] bg-white shadow-none;
      }
    }
  }

  .main-btns {
    @apply flex items-center justify-evenly gap-4;

    .sbmt-btn {
      @apply w-[105px] h-[36px] mx-[inherit]  border-none inline-block mt-8 bg-[#0296f4]  cursor-pointer hover:brightness-90 transition-all duration-300 text-white font-bold text-[15px] rounded;
    }

    .back-btn {
      @apply mx-[inherit] w-auto  border-none inline-block mt-8 bg-transparent  cursor-pointer  transition-all duration-300   font-bold  p-4 rounded text-[#4a4a4a] text-[15px];
    }
  }
}

.form-radio2-main {
  @apply text-left mt-4 mb-14;

  label {
    @apply font-semibold flex gap-1 items-center cursor-pointer mb-8;

    span {
      @apply text-[#00a7ff];
    }
  }

  > p {
    span {
      @apply block text-black mt-2 mb-6;
    }
  }

  .required-text {
    @apply text-left text-[#00a7ff] italic text-base mt-2;
  }

  .form-radio2,
  .form-check2 {
    @apply flex gap-x-2 gap-y-2 accent-zinc-500  items-start mb-8;

    input {
      @apply my-0 min-w-[1.25rem] h-[1.25rem] cursor-pointer accent-[#0296f4];
    }

    label {
      @apply mb-0 font-medium;

      a, span {
        @apply leading-4;
      }
    }
  }

  .form-check2 {
    @apply items-start flex-wrap max-w-[320px];

    label {
      @apply md:max-w-[275px] max-w-[250px] w-full;
    }
  }
}

.form-text2-main {
  @apply text-left font-semibold;

  .required-text {
    @apply text-left text-[#00a7ff] italic text-base mt-2 font-normal;
  }
}

.step2,
.step3,
.step4,
.step {

  h6 {
    @apply mt-0 mb-9  text-center text-[18px] w-[330px] max-w-full tracking-[-0.54px];
  }
}

.step9 {
  @apply w-full;

  h6 {
    @apply text-center text-[18px] w-full;
  }

  .form-text2-main {
    @apply w-full;
  }
}

.clickable-content-small {
  @apply max-w-[330px] w-full text-sm -mt-8 mb-5;

  b {
    @apply font-bold;
  }

  p {
    @apply pt-1 mb-0 mt-0;
  }
}

.moreOptions {
  @apply grow w-full pl-[33px] flex items-center select-none cursor-pointer text-sm;

  ~ div {
    @apply text-sm  sm:pl-[40px] select-none;
  }
}

.progress {
  @apply max-w-[400px] w-full bg-[#c2e7ff] rounded-full h-1 mb-4 mx-auto;

  .progress-child {
    @apply bg-[#0c56d0] h-1 rounded-full;
  }
}

.step-desc {
  @apply text-sm text-right text-[#0c56d0] max-w-[400px] w-full mx-auto mt-20;
}

.ms-page .first_head {

  .h3 {
    @apply text-4xl;
  }

  .p {
    @apply text-xl;
  }
}

.justify_between {
  @apply justify-between;
}

.mt-adjust {
  @apply -mt-1;
}

.last-step {
  label {
    @apply mt-[2px];
  }
}
