.team {
  h1 {
    @apply text-center leading-[79.2px] mb-0 mt-[72px];
  }

  .team__lawyer__banner {
    @apply w-full md:w-3/4 mx-auto block;
  }

  .team__lawyers {
    @apply flex flex-wrap justify-center;

    .team__lawyer {
      @apply md:w-[270px] mx-[54px] md:mx-20 flex flex-col mt-[52px] md:mt-[100px];

      .team__lawyer__portrait {
        @apply w-[180px] h-[180px] md:w-64 md:h-64 self-center bg-cover;
      }

      .team__lawyer__link {
        @apply flex items-center mt-[25px] md:mt-[34px] ml-auto;

        a {
          @apply text-[15px] font-[500] text-[#454545] underline font-medium;
        }

        svg {
          transform: rotate(270deg);
          @apply md:mr-1;
        }
      }

      h6 {
        @apply mt-[20px] md:mt-[30px] mb-0 leading-[31.05px];
      }

      p {
        @apply my-0 mb-4 md:mb-[25px];

        &.body_2 {
          @apply leading-[30.59px] mb-0 text-base md:text-[19px] leading-[21px] md:leading-[31px] tracking-[-0.14px] md:tracking-[-0.19px];
        }

        small {
          @apply text-base md:text-[19px] leading-[34.2px] text-[#7b7b7b] leading-[17px] md:leading-[31px] tracking-[-0.14px] md:tracking-[-0.19px];
        }
      }

      .body_1 {
        @apply leading-[30.4px];
      }
    }
  }

  .team__video {
    @apply container bg-gray text-white my-24;

    .team__video__container {
      @apply p-12;

      h4 {
        @apply text-main mt-0;
      }
    }
  }
}
